































import Vue from 'vue'
import { Route, RawLocation, NavigationGuardNext } from 'vue-router'
import { login } from '@/api/admin'
import { adminToken } from '@/libs/local-store'
import store from '@/store'
export default Vue.extend({
	data() {
		return {
			formdata: {
				account: '',
				pswd: '',
			},
			formrules: {
				account: [{ required: true, trigger: 'blur', message: '请填写账号' }],
				pswd: [{ required: true, trigger: 'blur', message: '请填写密码' }],
			},
			formstate: {
				loading: false,
			},
		}
	},
	beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
		if (store.state.admin.info) return next(from as RawLocation)
		else
			return store
				.dispatch('admin/updateInfo', { show_message: false })
				.then(() => next(from as RawLocation))
				.catch(() => next())
	},
	methods: {
		onClickLogin() {
			;(this.$refs.formRef as any).validate((valid) => {
				if (!valid) return
				this.formstate.loading = true
				login(this.formdata)
					.then((res) => {
						adminToken.set(res.data)
						var desc = [
							{
								desc: '上线',
								style_color: '#52C41A',
							},
							{
								desc: '维护',
								style_color: '#FC881E',
							},
							{
								desc: '下线',
								style_color: '#BFBFBF',
							},
						]
						window.localStorage.setItem('desc', JSON.stringify(desc))
						window.localStorage.setItem('num', '0')

						return this.$store.dispatch('admin/updateInfo').then((admin) => {
							this.$message.success('登录成功')
							this.$router.replace({ name: 'home' })
						})
					})
					.finally(() => (this.formstate.loading = false))
			})
		},
	},
	mounted() {},
})
